.action-tracking-page-container p {
  margin: 0 !important;
}
.action-tracking-page-view-details {
  color: #6808ff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration-line: underline;
  padding-bottom: 10px;
}
.action-tracking-page-container {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // // align-items: center;
  // padding: 16px;
  // // margin: 20px;
  // border-radius: 16px;
  // border: 1px solid rgba(13, 15, 29, 0.1);
  // background: #fff;

  // position: relative;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid rgb(152 152 152 / 25%);
  background: #fff;
  // position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  // gap: 16px;
  overflow-x: auto;
  // width: 100%;
}
.action-tracking-page-card {
  // flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // width: fit-content;
  gap: 3px;
  position: relative;
  // flex: 1 1 auto;
  // height: 80px;
  // border: 1px solid red;
}
.action-tracking-icon-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background: #fff;

  // width: 100%;
}

.action-tracking-page-line {
  position: absolute;
  left: 43px;
  top: 15px;
  border-top: 1px dashed #b8b8b8;
  width: 94%;
  z-index: 1;
}

.action-tracking-card-date-visible {
  visibility: hidden;
}

.action-tracking-page-card-text p {
  color: #121212b2;
  font-size: 12px;
  white-space: nowrap;
}
.action-tracking-card-next-waiting-text p:first-child {
  color: #121212;
  font-weight: 700;
}

.action-tracking-step-number-container {
  display: flex;
  width: 30px;
  height: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #b8b8b8;
}
.action-tracking-step-number {
  // color: #fff;
  // font-size: 16px;
  // font-style: normal;
  // font-weight: 500;
  // line-height: 1;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.pulse {
  animation: pulse-animation 2s infinite;
  background: transparent;
  border-radius: 50%;
  // border: 1px solid #6808ff;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(102, 11, 221, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(102, 11, 221, 0);
  }
}
@media screen and (max-width: 768px) {
  .action-tracking-page-line {
    width: 90%;
  }
}
