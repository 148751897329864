//* In this file you will find helper functions and mixins

//* For adding shadow and padding to data tables

@mixin tableShadowAndPadding {
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px;
  border-radius: 8px;
  padding: 20px 20px 5px 20px;
}

//* For styling any text

@mixin textStyle($color, $size, $weight) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

//* For alignment

@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
