.team-card-container {
  display: -ms-grid;
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  grid-template-columns: repeat(auto-fit, 325px);
  gap: 25px;
  justify-content: center;
}

.team-card {
  height: 100%;
  min-width: 325px;
  border: 1px solid $gray-2;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  &__image-container {
    height: 100px;
    @include flex();
    img {
      display: block;
      max-height: 100%;
    }
  }
  &__header-container {
    height: 84px;
    @include flex(column);
    h2 {
      @include textStyle($black, 20px, 600);
      margin-bottom: 8px !important;
    }
    p {
      @include textStyle($gray-5, 14px, 400);
      margin-bottom: 0 !important;
    }
    .member-avatar-container {
      position: relative;
      @include flex();
      .member-count {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        p {
          color: #ff3e6c;
        }
      }
      .avatar {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 3px solid white;
        overflow: hidden;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
        img {
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  &__action-container {
    height: 64px;
    padding: 0 25px;
    @include flex(row, space-between, center);
    p {
      margin-bottom: 0 !important;
    }
  }
}
