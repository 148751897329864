.information-table-container {
  width: 100%;
  box-shadow: 0px 0px 10px $shadow;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
  h4 {
    margin: 1rem 0;
    color: $secondary-100;
  }
  img {
    padding-top: 8px;
    border-radius: 10px;
    max-width: 200px;
    max-height: 200px;
  }
}

.salary-information-table {
  border: 1px solid #d6d6d6;
  width: 100%;

  thead {
    background-color: #fff;
    border-bottom: 1px solid #d6d6d6;
    th {
      padding: 1.5rem;
      &:nth-child(2) {
        width: 30%;
        border-left: 1px solid #d6d6d6;
      }
    }
  }

  tr {
    border-right: 1px solid #d6d6d6;
    &:last-child {
      border-top: 1px solid #d6d6d6;
      td {
        padding: 1rem;
      }
    }
    td {
      padding: 0.5rem 1rem;
      &:nth-child(2) {
        width: 30%;
        border-left: 1px solid #d6d6d6;
      }
    }
  }
}

.date-input {
  position: relative;
  svg {
    position: absolute;
    top: 25%;
    right: 5%;
    color: black;
  }
}

.pay-slip-modal {
  min-width: 70%;
  .btn.btn-danger,
  .btn.btn-error {
    padding: 0px 8px;
    border-radius: 5px;
  }
}

.salary-sheet-table {
  thead {
    background-color: $gray-1;
  }
  tr {
    padding: 1rem;
  }
  tr:last-child {
    font-weight: bold;
  }
}
