.evaluation-buttons {
  position: relative;
  .inactive {
    background-color: transparent;
    border: none;
    outline: none;
    padding-bottom: 5px;
    @include textStyle($gray-5, 1rem, 400);
  }
  .active {
    @extend .inactive;
    @include textStyle($primary-100, 1rem, 500);
    border-bottom: 2px solid $primary-100;
  }
  button {
    margin-right: 20px;
  }
  hr {
    margin: 0;
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
  }
}
