.file-card-container {
  display: -ms-grid;
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  grid-template-columns: repeat(auto-fit, 325px);
  gap: 25px;
  justify-content: center;
}

.file-card {
  height: 206px;
  min-width: 325px;
  border: 1px solid $gray-2;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  &__image-container {
    height: 170px;
    width: 323px;
    object-fit: contain;
    overflow: "hidden";
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
    @include flex();
    img {
      display: block;
      // max-height: 100%;
      // max-width: 100%;
    }
  }
  &__header-container {
    height: 84px;
    @include flex(column);
    h2 {
      @include textStyle($black, 20px, 600);
      margin-bottom: 8px !important;
    }
    p {
      @include textStyle($gray-5, 14px, 400);
      margin-bottom: 0 !important;
    }
    .member-avatar-container {
      position: relative;
      @include flex();
      .member-count {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        p {
          color: #ff3e6c;
        }
      }
    }
  }
  &__action-container {
    height: 60px;
    padding: 0;
    background: rgba(9, 15, 46, 0.53);
    backdrop-filter: blur(5px);
    border-radius: 0px 0px 13px 13px;
    @include flex(row, space-between, center);
    p {
      margin-bottom: 0 !important;
      margin-left: 15px;
      color: #ffff;
      font-weight: 400;
    }
  }
}
