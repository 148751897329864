.employee-card-container {
  overflow: auto;
  max-height: 380px;
  .employee-card {
    height: 60px;
    margin-bottom: 20px;
    @include flex(row, flex-start, center);
    &__image-container {
      height: 60px;
      width: 60px;
      overflow: hidden;
      border-radius: 8px;
      margin-right: 20px;
      img {
        display: block;
        width: 100%;
      }
    }
    &__text-container {
      h5 {
        @include textStyle($black, 16px, 500);
        margin-bottom: 10px !important;
      }
      p {
        @include textStyle($gray-4, 14px, 400);
        margin: 0 !important;
      }
    }
  }
}
