.c-sidebar {
  padding-top: 6px;
  background-color: #171431;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  .c-sidebar-brand {
    background-color: transparent;
  }
  .c-sidebar-nav {
    .c-sidebar-nav-item {
      padding: 0 10px;
    }
    .c-sidebar-nav-dropdown {
      padding: 0 10px;
    }
    .c-sidebar-nav-link {
      color: #ffffff !important;
      padding: 12px;
      margin: 10px 0px;
      border-radius: 8px;
      .c-sidebar-nav-icon {
        color: #ffffff !important;
      }
    }
  }
}

.c-sidebar-nav-dropdown-items {
  .c-sidebar-nav-item {
    padding: 0 !important;
  }
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background-color: #2a225f;
  color: #fff !important;
}

.c-sidebar-nav-item a.c-sidebar-nav-link[aria-current="page"] {
  background-color: #231d4e;
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
}
