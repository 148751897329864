.form-container {
  padding: 0 0.5rem 1rem 0.5rem;
}
.button-bg {
  background-color: $primary-100;
  display: block;
  border-radius: 8px;
  color: #ffffff;
  padding: 0.75rem 3rem;
  font-size: 16px;
  border: none;
  margin-bottom: 0.85rem;
  &:hover {
    cursor: pointer;
    background-color: darken($color: $primary-100, $amount: 3);
    color: #ffffff;
  }
}

.button-bg-danger {
  @extend .button-bg;
  background-color: $error;
  &:hover {
    background-color: darken($color: $error, $amount: 3);
  }
}

.button-bg-gray {
  @extend .button-bg;
  background-color: $gray-2;
  &:hover {
    background-color: darken($color: $gray-2, $amount: 3);
  }
}

.button-bg-success {
  @extend .button-bg;
  background-color: $success;
  &:hover {
    background-color: darken($color: $success, $amount: 3);
  }
}

.button-md {
  background-color: $primary-100;
  height: calc(1.5em + 1.2rem);
  display: block;
  border-radius: 8px;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  border: none;
  margin-bottom: 0.85rem;
  &:hover {
    cursor: pointer;
    background-color: darken($color: $primary-100, $amount: 3);
    color: #ffffff;
  }
}

.button-md-outline {
  background-color: transparent;
  height: calc(1.5em + 1.2rem);
  display: block;
  border-radius: 8px;
  color: $primary-100;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  border: 1px solid $primary-100;
  margin-bottom: 0.85rem;
  &:hover {
    cursor: pointer;
    background-color: darken($color: $primary-10, $amount: 3);
  }
}

.input-button {
  background-color: $primary-100;
  display: block;
  border-radius: 8px;
  color: #ffffff;
  padding: 0.75rem 2rem;
  font-size: 16px;
  &:hover {
    background-color: darken($color: $primary-100, $amount: 3);
    color: #ffffff;
  }
}
.input-container {
  // max-width: 350px;
}

.input-label {
  display: block;
  color: $gray-4;
  margin-bottom: 8px;
}
.input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem);
  padding: 0.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #d6d6d6;
  color: $black;
  background-color: #ffffff;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 0.85rem;
  &:focus {
    border: 2.5px solid $primary-100;
    outline: 0;
  }

  &:read-only,
  &:read-only:focus {
    background-color: $gray-1;
    border: 1px solid #d6d6d6;
  }
  &::placeholder {
    color: $gray-2;
  }
}
.text-area {
  display: block;
  width: 100%;
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #d6d6d6;
  color: $black;
  background-color: #ffffff;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 0.85rem;
  &:focus {
    border: 2.5px solid $primary-100;
    outline: 0;
  }
  &:read-only,
  &:read-only:focus {
    background-color: $gray-1;
    border: 1px solid #d6d6d6;
  }
}
.input-error {
  font-size: 0.75rem;
  color: $error;
  margin: 0;
  padding: 0;
}

// * React-select - Structure
// <div class="react-select-container">
//   <div class="react-select__control">
//     <div class="react-select__value-container">...</div>
//     <div class="react-select__indicators">...</div>
//   </div>
//   <div class="react-select__menu">
//     <div class="react-select__menu-list">
//       <div class="react-select__option">...</div>
//     </div>
//   </div>
// </div>
.select-container {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;

  color: $gray-4;
  // background-color: #ffffff;
  margin-bottom: 0.85rem;
  .select__indicator-separator {
    display: none;
  }
  .select__control {
    border-radius: 8px;
    height: 100%;
    border: 1px solid #d6d6d6;
  }
  .select__control--is-focused {
    border: 2px solid $primary-100;
    box-shadow: none;
    box-sizing: border-box;
  }
}

.multi-select-container {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 1.5rem);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  color: $gray-4;
  background-color: #ffffff;
  margin-bottom: 0.85rem;
  .multi-select__indicator-separator {
    display: none;
  }
  .multi-select__control {
    border-radius: 8px;
    min-height: calc(1.5em + 1.5rem);
  }
  .multi-select__control--is-focused {
    border: 2px solid $primary-100;
    box-shadow: none;
    box-sizing: border-box;
  }
}

// Select scrollbar customization
/* Scroll Bar */
.select__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
.select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.select__menu-list::-webkit-scrollbar-thumb {
  background: #888;
}
.select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Make the input fields go to right
.margin-left-on-child .input-container {
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .input-container {
    margin: auto;
    width: 100%;
  }
}

.pass-view-icon {
  position: absolute;
  top: 42px;
  left: calc(100% - 35px);
}
.file-upload-container p {
  letter-spacing: normal !important;
}
