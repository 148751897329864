.user-dashboard-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 470px);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin: 25px 0 50px 0;

  .section1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .section2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .section3 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .section4 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .section5 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .section6 {
    grid-area: 3 / 2 / 4 / 3;
  }
}

@media only screen and (max-width: "1200px") {
  .user-dashboard-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 470px);
    .section1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .section2 {
      grid-area: 2 / 1 / 3 / 2;
    }
    .section3 {
      grid-area: 3 / 1 / 4 / 2;
    }
    .section4 {
      grid-area: 4 / 1 / 5 / 2;
    }
    .section5 {
      grid-area: 5 / 1 / 6 / 2;
    }
    .section6 {
      grid-area: 6 / 1 / 7 / 2;
    }
  }
}
