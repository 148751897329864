.user-detailed-information .profile-tab {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 25px;
  &__header {
    cursor: pointer;
    background-color: $primary-10;
    @include flex(row, space-between, center);
    padding: 18px;
    p {
      margin-bottom: 0;
      @include textStyle($black, 18px, 500);
    }
  }
}
.user-detailed-information .tab-content {
  padding: 30px 40px;
  div {
    @include flex(row, flex-start, center);
    margin-bottom: 15px;
    p {
      @include textStyle($gray-4, 14px, 400);
      min-width: 200px;
      max-width: 200px;
      margin-bottom: 0;
    }
    h6 {
      @include textStyle(#000, 14px, 500);
      margin-bottom: 0;
      margin-left: 10px;
      position: relative;
      &::before {
        content: ":";
        margin-left: -10px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }
  }
  .photo-document {
    @include flex(column, flex-start, flex-start);
    h2 {
      @include textStyle(#000, 14px, 500);
      margin-bottom: 15px;
    }
    .image-container {
      @include flex(row, flex-start, flex-start);
      div {
        width: 150px;
        @include flex(column, flex-start, flex-start);
        p {
          margin-bottom: 15px;
        }
        .image {
          height: 100px;
          width: 100px;
          background-color: $gray-2;
          @include flex();
          padding: 10px;
          border-radius: 8px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .tab-content {
    padding: 10px 20px;
    div {
      p {
        width: 50%;
      }
      h6 {
        width: 50%;
        &::before {
          content: ":";
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }
    .photo-document {
      @include flex(column, flex-start, flex-start);
      .image-container {
        @include flex(column);
        div {
          p {
            width: 100%;
            margin-bottom: 15px;
          }
          @include flex(column, flex-start, flex-start);
        }
      }
    }
  }
}
