// .column-item {
//   width: 250px;
//   height: 120px;
//   border: 1px solid white;
//   border-radius: 10px;
//   padding: 10px;
// }
// .icon {
//   font-size: 2rem;
// }
// .description {
//   font-size: 1.2rem;
// }
// .amount {
//   font-size: 1rem;
//   color: grey;
// }
// .gap-10 {
//   gap: 4px;
// }

// .advanced-budget-container{
//   display: flex;
//   justify-content: space-evenly;
//   gap: 16px; // gap between the cards
//   flex-wrap: wrap;

//   .card {
//     display: flex;
//     padding: 16px;
//     border: 1px solid #ccc;
//     border-radius: 8px;
//     width: 200px;
//     height: 115px;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

//     .icon {
//       font-size: 24px;
//     }

//     .text-and-amount {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;

//       .text {
//         font-size: 14px;
//         font-weight: bold;
//       }

//       .amount {
//         font-size: 16px;
//         font-weight: bold;
//         color: #333;
//       }
//     }
//   }
// }

// Responsive styles
// @media (max-width: 768px) {
//   .container {
//     justify-content: center;

//     .card {
//       width: 100%; // Full width on smaller screens
//       max-width: 90%;
//       margin-bottom: 16px;
//     }
//   }
// }

// @media (max-width: 480px) {
//   .container {
//     flex-direction: column;

//     .card {
//       width: 100%;
//       max-width: none;
//     }
//   }
// }
input::placeholder {
  font-weight: bolder !important;
  color: gray;
}
.advanced-budget-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(210px, 1fr)
  ); // Makes the grid responsive
  grid-gap: 16px; // Gap between cards

  .card {
    display: grid;
    // align-items: center;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    height: 115px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .icon {
      font-size: 24px;
    }

    .text-and-amount {
      display: flex;
      flex-direction: column;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;

      .text {
        font-size: 14px;
        font-weight: bold;
      }

      .amount {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}

// Responsive grid changes for smaller screens
@media (max-width: 768px) {
  .container {
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); // Adjusts card size for smaller screens
  }
}

@media (max-width: 480px) {
  .container {
    grid-template-columns: 1fr; // Stacks cards in a single column
  }
}

/* The switch - the container */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

/* Hide the default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 30px;
}

/* The slider before the toggle */
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checked state */
input:checked + .slider {
  background-color: #2196f3;
}

/* Move the slider when checked */
input:checked + .slider:before {
  transform: translateX(16px);
}
