// Here you can add other styles
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

// Support for tooltip on disabled button
// @link https://stackoverflow.com/questions/13311574/how-to-enable-bootstrap-tooltip-on-disabled-button
.btn.disabled {
  pointer-events: auto;
}

// custom class
.gy-4 {
  .col-md-6 {
    margin: 0.875rem 0;
  }
}

// Utility
.text-xs {
  font-size: 12px;
}

.hq-shadow {
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px;
  border-radius: 8px;
}

.rounded-sm {
  border-radius: 8px !important;
}
.bg-hq {
  background-color: $primary-100;
}
.hq-button {
  color: #fff;
  background-color: $primary-100 !important;
  &:hover {
    color: #fff;
    background-color: $primary-101 !important;
  }
}

.hq-shadow-10 {
  box-shadow: 0px 0px 13px rgba(9, 30, 66, 0.1);
  border-radius: 10px;
}

// overrides
.c-body {
  background-color: #f7f7f7;
}
.nav-tabs {
  border-bottom: none; //1px solid #ededed;
  .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: $primary;
    background-color: $primary-10;
    border-color: $gray-2 $gray-2 $primary-10;
  }
}
.plus-icon-dropdown.dropdown-toggle::after {
  display: none;
}
.plus-icon-dropdown.dropdown-toggle {
  outline: none;
  box-shadow: none;
}

.table-striped > tbody > tr:nth-child(odd) {
  background-color: white; // Choose your own color here
}
.table-striped > tbody > tr:nth-child(even) {
  background-color: rgb(13 15 29 / 5%); // Choose your own color here
}
