.user-info-container {
  .personal-info,
  .social-info,
  .office-info,
  .banking-info {
    margin: 35px 0;
    p {
      color: $gray-4;
      font-weight: 400;
    }
    h6 {
      color: $black;
      font-weight: 500;
    }
    .info-field {
      margin: 30px 0;
    }
  }
}
