.previews-submission-container {
  width: 40%;
  background-color: #ffffff;
}
.final-submission-container {
  width: 57%;
  // padding: 24px;
  // border: 1px solid #e6e6e6;
  // border-radius: 8px;
  // background-color: #fafafb !important;
}

@media screen and (max-width: 768px) {
  .previews-submission-container {
    width: 100%;
  }

  .final-submission-container {
    width: 100%;
  }
}
