.cursor-pointer {
  cursor: pointer !important;
}

.border-radius-8 {
  border-radius: 8px;
}

.field-name {
  @include textStyle($gray-4, 14px, 400);
}

.field-data {
  @include textStyle($black, 14px, 400);
}

.font-weight-500 {
  font-weight: 500 !important;
}

.button-outline-primary {
  border: 1px solid $primary-100;
  border-radius: 5px;
  background-color: transparent;
  color: $primary-100;
  &:hover {
    background-color: lighten($color: $primary-100, $amount: 40%);
  }
}

.button-ghost-primary {
  color: $primary-100;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
}

.search-box {
  width: 275px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid $gray-2;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.max-w-900 {
  max-width: 900px;
}
.font-weigth-500 {
  font-weight: 500;
}

.dropdown-item:active {
  text-decoration: none;
  color: #ffffff;
  background-color: $primary-100;
}
