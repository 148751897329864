.event-card-container {
  border-radius: 8px;
}
.event-card-borderless {
  border: 2px solid #e8f1ff;
  border-radius: 8px;
}

.birthday-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  .name {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }
  .remain-days {
    font-size: 14px;
    font-weight: 400;
    color: #555555;
  }
  .team {
    font-size: 12px;
    font-weight: 400;
    color: #b9b9c3;
  }
  .b-day {
    font-size: 15px;
    font-weight: 400;
    color: #5e5873;
  }
  .b-date {
    font-size: 21px;
    font-weight: 600;
    color: #4289ff;
  }
  p {
    margin: 0;
  }
  img {
    border: 3px solid white !important;
  }
  &:hover {
    background-color: #e8f1ff;
  }
}
.highlighted-birthday {
  background-color: #e8f1ff;
}
.holiday-card {
  p {
    margin: 0;
  }
  &:hover {
    background-color: #e8f1ff;
  }
  .calender {
    width: 50px;
    background-color: #5d57e91a;
    padding: 10px 11px;
    border-radius: 5px;
    margin-right: 8px;
  }
  .name {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }
  .date {
    font-size: 14px;
    font-weight: 500;
    color: #555555;
  }
  .total-days {
    font-size: 12px;
    font-weight: 400;
    color: #b9b9c3;
  }
  .b-day {
    font-size: 14px;
    font-weight: 500;
    color: #5e5873;
  }
  .b-date {
    font-size: 21px;
    font-weight: 600;
    color: #4289ff;
  }
}
.calender-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 5px;
  background-color: #5d57e91a;
}

.upcoming-event {
  display: grid;
  grid-template-columns: none;
  gap: 25px;
}

.session-event {
  height: 100%;
  // display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 11px;
  row-gap: 10px;
  // border: 1px solid black;
}

.session-card {
  // height: fit-content;
  // margin-bottom: 10px;
  border-radius: 10px;
  padding: 25px 15px;
  border: 1px solid #e8f1ff;
  margin-bottom: 10px;

  .s-date {
    width: fit-content;
    border: 1px solid #a2babe52;
    padding: 5px 8px;
    border-radius: 5px;
    color: black;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }
  .s-name {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }
  .s-time {
    font-size: 14px;
    font-weight: 400;
    color: #9590a0;
  }
}
.overlap-teams {
  margin-top: 4px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: -0.5em;
    border: 2px solid white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
.session-container {
  display: grid !important;
  grid-template-columns: none !important;
  grid-template-rows: repeat(1fr) !important;
  gap: 25px;
}
// townhall event image
.townhall-meeting {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 9px;
}

$xs: 0;
$sm: 876px;
$md: 876px;
$lg: 903px;
$xl: 1130px;
$xxl: 1400px;

@media (min-width: $sm) {
  .upcoming-event {
    grid-template-columns: auto auto;
  }
}
@media (min-width: $lg) {
  .upcoming-event {
    grid-template-columns: auto auto;
  }
}
@media (min-width: $xl) {
  .upcoming-event {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .session-container {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (min-width: $xxl) {
  .upcoming-event {
    grid-template-columns: 2fr 2fr 2fr;
  }
  .session-container {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (max-width: $xxl) {
  .session-event {
    grid-template-columns: auto !important;
  }
}
