.employee-info-container {
  display: flex;
  justify-content: space-between;
  .employee-info {
    width: 100%;
    overflow-x: auto;

    h4 {
      margin-bottom: 22px;
    }
    div {
      display: grid;
      grid-template-columns: 150px 1fr;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        width: 150px;
      }
      h6 {
        margin-bottom: 0;
        width: max-content;
        // word-wrap: break-word;
        &::before {
          content: ":";
          margin-right: 25px;
        }
      }
    }
  }
  .employee-image-container {
    max-height: 200px;
    max-width: 200px;
    border-radius: 10px;
    overflow: hidden;
    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .employee-info {
    margin: 0 auto;
    margin-top: 25px;
  }
  .employee-image-container {
    margin: 0 auto;
  }
}
