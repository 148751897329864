@import "./userDashboard";

.dashboard-card {
  background: #fff;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px;
  border-radius: 8px;
  padding: 25px;
  overflow: hidden;
  &__header {
    @include flex(row, space-between, center);
    @include textStyle(#000, 20px, 500);
  }
}

@import "./totalEmployee";
@import "./evaluation";
@import "./salaryStatistics";
@import "./leaveStatistics";
@import "./monthlyExpense";
@import "./_bonusStatistics";
