.primary-modal {
  &__header {
    button {
      border: none;
      outline: none;
      padding: 0;
      &:hover {
        background-color: #fff;
      }
    }
  }
  div {
    border-radius: 24px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9997;
  overflow-y: auto;
}

.modal-container {
  position: relative;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  z-index: 9999;
  border-radius: 24px;
  margin-bottom: 150px;
  .modal-header-container {
    @include flex(row, space-between, center);

    margin-bottom: 0;
    padding: 15px;
    border-bottom: 1px solid $gray-2;
    p {
      font-size: 1rem;
      color: $black;
      font-weight: 500;
    }
    button {
      border: none;
      outline: none;
      padding: 0;
      &:hover {
        background-color: #fff;
      }
    }
  }
  .modal-body-container {
    padding: 1.5rem;
  }
}

.modal-lg {
  width: 750px;
}
@media only screen and (max-width: 600px) {
  // .modal-overlay {
  //   display: none;
  // }
  .modal-container {
    width: 90% !important;
  }
  .modal-lg {
    width: 90%;
  }
}
