.input-card {
  @include tableShadowAndPadding();
  position: relative;
  padding-top: 70px;
  margin-bottom: 25px;
  &__header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 70px;
    border-bottom: 1px solid $gray-2;
    @include flex(row, flex-start, center);
    h1 {
      margin: 0 0 0 30px !important;
      @include textStyle($gray-5, 20px, 500);
    }
  }
  &__body {
    label {
      @include textStyle($gray-5, 14px, 400);
    }
    input {
      height: 48px;
    }
    .final-output {
      margin: 25px;
      @include flex(row, space-between, center);
      div {
        margin-right: 50px;
        h6 {
          @include textStyle($gray-4, 14px, 400);
        }
        p {
          margin-bottom: 0 !important;
          @include textStyle($black, 14px, 500);
        }
      }
    }
  }
}
