.evaluation-reports{
  table{
    tbody{
      tr{
        td{
          border: 1px solid #D6D6D6;
          padding: 20px 10px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
}