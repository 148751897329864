.status-card-container {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(196px, 1fr));
  grid-gap: 25px;
}

.status-card {
  background: #ffffff;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px;
  border-radius: 8px;
  padding: 25px;
  min-width: 200px;
}
