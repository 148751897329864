.delete-confirmation {
  &__image {
    height: 290px;
    background-color: rgba(64, 123, 255, 0.1);
    @include flex();
  }
  &__body {
    .delete-confirmation-text {
      margin: 25px 0 !important;
      @include flex(column);
      h1 {
        @include textStyle($black, 1.25rem, 500);
      }
      p {
        @include textStyle($gray-5, 1rem, 400);
      }
    }
    .delete-confirmation-buttons {
      @include flex();
      padding-bottom: 40px !important;
      button {
        width: 150px;
        margin: 0 15px !important;
      }
      :not(.button-bg-gray){
        background-color: $error;
      }
    }
  }
}
