.loan-status-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(196px, 1fr));
  grid-gap: 25px;
  .loan-status {
    background: #ffffff;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px;
    border-radius: 8px;
    padding: 25px;
    min-width: 200px;
  }
}

.loan-management-table-container {
  .loan-management-table-header {
    margin-bottom: 20px;
    .table-title {
      p {
        @include textStyle($gray-5, 20px, 500);
      }
    }
  }

  thead {
    tr {
      background-color: $primary-10;
      th {
        border: none;
        @include textStyle($black, 14px, 500);
      }
    }
  }
  tbody {
    tr {
      .action-buttons {
        height: 66px;
      }
      td {
        border: none;
        @include textStyle($gray-5, 14px, 400);
        vertical-align: middle;
      }
    }
  }
}
