// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}


input:read-only:focus{
  outline: none;
  border: none;
  box-shadow: none;
}

// toggle button background
.c-switch-primary .c-switch-input:checked + .c-switch-slider{
  background-color: #680aff;
}