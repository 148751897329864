.employee-search{
  img{
    height: 40px;
    width: 40px;
  }
  
  .search-result-item{
    transition: 0.5s all ease;
    cursor: pointer;
    &:hover{
      background-color: $primary-100;
      color: white;
    }

  }
}

.selected-tags{
  background-color: rgba(104, 8, 255, 0.1);
  color: $primary-100;
  display: inline-block;
  margin: 10px 5px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  span{
    padding: 5px;
    cursor: pointer;
  }
}