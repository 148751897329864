.login {
  height: 100vh;
  width: 100%;
  padding-top: 50px;
  @include flex();
  .login-container {
    @include flex(row, center, stretch);
    .login-input-container {
      background-color: $white;
      form {
        width: 450px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .login {
    .login-container {
      .login-banner-container {
        display: none !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .login {
    .login-container {
      .login-input-container {
        margin: 0 15px;
        form {
          width: 95%;
          margin: 0 auto !important;
          h4 {
            margin: 40px 0 !important;
          }
          button {
            margin-bottom: 40px !important;
          }
        }
      }
    }
  }
}
