.team-members-table-container {
  @include tableShadowAndPadding();
  .team-member-table-header {
    margin-bottom: 20px;
    .table-title {
      p {
        @include textStyle($gray-5, 20px, 500);
      }
    }
  }
  thead {
    tr {
      background-color: $primary-10;
      th {
        border: none;
        @include textStyle($black, 14px, 500);
      }
    }
  }
  tbody {
    tr {
      td {
        border: none;
        @include textStyle($gray-5, 14px, 400);
      }
    }
  }
}

.invitation-modal {
  &__header {
    p {
      @include textStyle($black, 16px, 500);
    }
    button {
      border: none;
      outline: none;
      padding: 0;
    }
  }
  div {
    border-radius: 24px;
    width: 430px;
  }
  #designation,
  #employee-type,
  #team {
    width: 100%;
    div {
      width: 100%;
      border-radius: 8px;
      margin-top: 5px;
    }
  }
}

@media only screen and (max-width: 690px) {
  .team-members-table-container {
    .team-member-table-header {
      flex-direction: column;
      .table-title {
        text-align: center;
      }
      .search-input {
        margin: 20px 0 20px 0 !important;
        height: 48px;
        input {
          width: 100%;
        }
      }
      .add-member-button {
        margin-left: auto;
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}
