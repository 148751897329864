.manage-bonus-table-container {
  @include tableShadowAndPadding();
  .manage-bonus-table-header {
    margin-bottom: 20px;
    .table-title {
      p {
        @include textStyle($gray-5, 20px, 500);
      }
    }
  }
}
