.form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  //   visibility: hidden;
  min-height: 6em;
  outline: none;
  cursor: pointer;
  background-color: #c61c23;
  box-shadow: 0 0 5px solid;
  border: 1px solid gray;
  border-style: dashed;
  border-radius: 5px;
  opacity: 0;
}
.upload-container {
  text-align: center;
  min-height: 203px;
  position: relative;
  border: 1px solid gray;
  border-style: dashed;
  border-radius: 16px;
}
.form-grid {
  display: grid;
  grid-template-columns: auto auto !important;
  column-gap: 28px;
}

$md: 573px;

@media (max-width: $md) {
  .form-grid {
    grid-template-columns: auto !important;
  }
}
