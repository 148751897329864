.user-profile {
  background: #ffffff;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px;
  border-radius: 8px;
  margin: 0 auto 50px auto;
  max-width: 1400px;
  .cover-photo-container {
    width: 100%;
    height: 150px;
    img {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 8px 8px 0 0;
    }
  }

  .user-information-container {
    @include flex(row, space-evenly, flex-start);
    padding: 20px 20px 0 0;
    .profile-card {
      width: 30%;
      max-width: 365px;
      position: relative;
      padding: 15px;
      &__image-container {
        height: 150px;
        width: 150px;
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        position: absolute;
        top: -90px;
        left: 50%;
        transform: translateX(-50%);
        label {
          position: absolute;
          right: 10px;
          bottom: 10px;
          cursor: pointer;
        }
        input {
          display: none;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        button {
          position: absolute;
          bottom: 5px;
          right: 5px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.5);
          border: none;
          @include flex();
          &:focus {
            outline: none;
          }
        }
      }
      .user-info {
        padding-top: 60px;
        @include flex(column);
        .separator {
          width: 150px;
          height: 1px;
          margin: 10px auto;
          background: linear-gradient(90deg, white, $gray-3, white);
        }
        .basic-info {
          @include flex(column);
          h5 {
            @include textStyle($black, 20px, 500);
          }
          p {
            margin-bottom: 0;
            font-size: 14px;
            color: $gray-5;
            margin-bottom: 3px;
          }
        }
        .social-links {
          padding-left: 20px;
          width: 100%;
          a {
            text-decoration: none;
            color: $information;
            margin: 10px 0;
            @include flex(row, flex-start, center);
            p {
              margin-left: 10px;
              margin-bottom: 0;
            }
          }
        }
      }

      .user-action {
        margin-top: 10px;
        @include flex(column, center, stretch);
        &__button {
          padding: 10px 20px;
          margin-bottom: 10px;
          border-radius: 8px;
          cursor: pointer;
          text-decoration: none;
          color: $gray-5;
          &:hover {
            background-color: $primary-10;
          }
          @include flex(row, flex-start, center);
          p {
            margin-bottom: 0;
            margin-left: 10px;
          }
        }
        &__button--active {
          background-color: $primary-10;
          color: $black;
          font-weight: 500;
        }
      }
    }
    .user-detailed-information {
      width: 70%;
      max-width: 900px;
    }
  }
}

.edit-profile-container {
  width: 70%;
  max-width: 900px;
}

.change-password-container {
  width: 70%;
  max-width: 900px;
  border: 1px solid $gray-2;
  border-radius: 8px;
  margin-bottom: 50px;
  .change-password-header {
    background-color: $primary-10;
    @include flex(row, space-between, center);
    padding: 18px;
    p {
      margin-bottom: 0;
      @include textStyle($black, 18px, 500);
    }
  }
  .change-password-form {
    @include flex();
    padding: 20px 0;
    form {
      width: 360px;
    }
    h2 {
      display: block;
      width: 100%;
      height: 60px;
      background-color: $primary-10;
      @include flex();
      @include textStyle(#000, 20px, 500);
    }
  }
}

@media only screen and (max-width: 1100px) {
  .user-profile {
    .user-information-container {
      @include flex(column);
      padding: 20px;
      .profile-card {
        width: 100%;
      }
      .user-detailed-information {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .user-profile {
    .user-information-container {
      padding: 10px;
      .profile-card {
        &__image-container {
          height: 100px;
          width: 100px;
        }
        .user-info {
          padding-top: 30px;
        }
      }
    }
  }
}
