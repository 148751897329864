.file-upload-label {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 500;
  color: #0d0f1dcc;
}

.required-indicator {
  color: red;
}

.upload-instructions {
  font-size: 14px;
  color: #666;
}

.browse-text {
  color: #007bff;
  cursor: pointer;
}

.file-preview-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.preview-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.preview-container {
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-container.uploaded {
  border: 3px solid #02b254;
}

.file-preview-image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.file-meta-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.file-meta-data aside {
  display: flex;
  gap: 8px;
  align-items: center;
}

.delete-icon {
  cursor: pointer;
  color: #ff4d4f;
}
