.image-preview-container {
  width: 100%;
  @include flex(column, flex-end, flex-end);
  button {
    margin-bottom: 15px;
  }
  img {
    display: block;
    width: 100%;
  }
}
