.data-table-container {
  position: relative;
  .table-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: $gray-5;
  }
  .data-table {
    thead {
      background-color: $primary-10;
      tr {
        th {
          border: none;
          color: $black;
          font-weight: 500;
        }
      }
    }
    tbody {
      tr {
        td {
          border: none;
          color: $gray-5;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .data-table-container {
    width: 100%;
    overflow-x: scroll;
  }
}

.pagination {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
  a {
    height: 30px;
    width: 30px;
    margin: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #f5f5f5;
    border-radius: 8px;
    transition: 0.3s all;
    &:hover {
      background-color: $primary-100;
      color: white;
    }
  }
  li.disabled a {
    color: $gray-5;
    &:hover {
      background-color: white;
      cursor: not-allowed;
    }
  }
  .active-page a {
    background-color: $primary-100;
    color: white;
  }
  .previous,
  .next {
    font-size: 1.5rem;
    color: $primary-100;
  }
}

.salary-pay-loan-table {
  margin: 1rem 0;
  .table-head {
    display: flex;
    background-color: $primary-10;
    div {
      flex: 1;
      padding: 1rem;
      font-weight: bold;
      border-top: 1px solid $gray-2;
      border-bottom: 1px solid $gray-2;
      &:first-child {
        border-left: 1px solid $gray-2;
      }
      &:last-child {
        flex: 0 0 30%;
        border-left: 1px solid $gray-2;
        border-right: 1px solid $gray-2;
      }
    }
  }
  .table-body {
    div {
      display: flex;
      div {
        flex: 1;
        padding: 1rem;
        border-bottom: 1px solid $gray-2;
        &:first-child {
          border-left: 1px solid $gray-2;
        }
        &:last-child {
          flex: 0 0 30%;
          border-left: 1px solid $gray-2;
          border-right: 1px solid $gray-2;
        }
      }
    }
  }
}
