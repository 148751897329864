.navbar-container {
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, #684efb 0.01%, #5a00dd 100.01%);
  padding: 0 130px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  @include flex(row, space-between, center);
  .navbar-logo-container {
    height: 80%;
    .phone-logo {
      display: none;
    }
  }
  .navbar-route-container {
    list-style: none;
    margin-bottom: 0 !important;
    @include flex();
    li {
      @include textStyle($white, 1rem, 500);
      margin-left: 30px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .navbar-container {
    justify-content: space-evenly;
    padding: 0;
  }
}

@media only screen and (max-width: 550px) {
  .navbar-container {
    .navbar-logo-container {
      .desktop-logo {
        display: none;
      }
      .phone-logo {
        display: block;
        height: 100%;
        img {
          display: block;
          height: 100%;
        }
      }
    }
  }
}
