.create-budget-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  .budget-card {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }

  .label-card {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.024px;
    color: #0d0f1dcc;
    margin: 0px !important;
  }
  .input-field {
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    padding: 12px;
    background: #fff;
    // width: 270px;
    outline-color: blue;
  }
}

@media screen and (max-width: 768px) {
  .create-budget-container {
    flex-direction: column;
    gap: 0px;
  }
}
