.tab {
  cursor: pointer;
  height: 60px;
  border-radius: 8px;
  border: 1px solid $gray-2;
  padding: 15px;
  margin: 15px 0;
}

.tab-title {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}
.tab-title-icon {
  height: 20px;
  width: 20px;
  border: 4px solid $primary-100;
  border-radius: 50%;
  margin-right: 5px;
}
.tab-chevron {
  height: 25px;
  width: 25px;
  border: 1px solid gray;
  border-radius: 50%;
}
.tab-child {
  overflow: hidden;
  height: 0;
  transition: height 0.5s cubic-bezier(0.61, 0.29, 0.22, 0.9);
}
.show-tab {
  height: auto;
  overflow: visible;
}

@media screen and (max-width: 768px) {
  .tab {
    max-width: 350px;
    margin: 15px auto;
  }
}
// @keyframes reveal {
//   0% {
//     height: 0;
//   }
//   100% {
//     height: 100%;
//   }
// }
