.action-tracking-container p {
  margin: 0 !important;
  height: auto;
}
.action-tracking-card {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  gap: 12px;
  height: 65px;
}

.action-tracking-icon-container {
  flex: 1;
  position: relative;
  height: 90%;
}
.action-tracking-icon::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 29px;
  left: 15px;
  border-left: 1px dashed #b8b8b8;
}
.action-tracking-card-date-visible {
  visibility: hidden;
}
.action-tracking-card-date {
  color: rgba(18, 18, 18, 0.7);
  flex: 0.3;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // width: 9%;
}
.action-tracking-card-text {
  flex: 2;
}
.action-tracking-card-text p {
  color: #121212b2;
  font-size: 14px;
  font-weight: 400;
}
.action-tracking-card-text p:first-child {
  color: #121212b2;
  font-size: 15px;
  font-weight: 500;
}
.action-tracking-card-next-waiting-text p:first-child {
  color: #121212;
  font-weight: 700;
}

.action-tracking-step-number-container {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 9px 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  // flex-shrink: 0;
  border-radius: 50%;
  background: #b8b8b8;
}
.action-tracking-step-number {
  // color: #fff;
  // font-size: 16px;
  // font-style: normal;
  // font-weight: 600;
  // line-height: normal;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.action-tracker-got-it {
  display: flex;
  justify-content: end;
  margin-right: 40px;
}
.action-tracker-got-it-button {
  padding: 13px 30px;
  background: #6808ff;
  color: white;
  font-weight: 600;
  border-radius: 12px;
  font-size: 17px;
  outline: none;
  border: none;
}
.action-tracker-got-it-button:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .action-tracking-card-date {
    flex: 0.6;
    font-size: 11px;
  }
  .action-tracking-card-date p {
    font-size: 10px;
  }
  .action-tracking-card {
    height: 80px;
  }
  .action-tracking-card-text p {
    font-size: 11px;
  }
  .action-tracking-card-text p:first-child {
    color: #121212b2;
    font-size: 12px;
    font-weight: 400;
  }
}
