// Variable overrides
$primary-101: #6200ff;
$primary-100: #680aff;
$primary-50: #b17fff;
$primary-10: #faf7ff;

$primary-100-dark: #1f1060;
$dark-bg: #06041f;

$secondary-100: #ff136f;
$secondary-50: #ff72a9;
$secondary-10: #feeaf2;

$information: #4289ff;
$warning: #ffd019;
$error: #ff0000;
$success: #02b254;
$pending: #fd9e3c;
$orange: #ff7044;
$purple: #853182;

$black: #0d0f1d;
$gray-5: #555555;
$gray-4: #858585;
$gray-3: #adadad;
$gray-2: #d6d6d6;
$gray-1: #f5f5f5;
$white: #ffffff;

$shadow: rgba(9, 30, 66, 0.1);

$theme-colors: (
  "primary-100-dark": $primary-100-dark,
  "primary-100": $primary-100,
  "primary-50": $primary-50,
  "primary-10": $primary-10,
  "primary": $primary-100,
  // changing default core ui color
  "secondary-100": $secondary-100,
  "secondary-50": $secondary-50,
  "secondary-10": $secondary-10,
  "information": $information,
  "warning": $warning,
  "error": $error,
  "success": $success,
  "orange": $orange,
  "purple": $purple,
  "pending": $pending,
  "dark": $black,
  "dark-bg": $dark-bg,
  "black": $black,
  "gray-5": $gray-5,
  "gray-4": $gray-4,
  "gray-3": $gray-3,
  "gray-2": $gray-2,
  "gray-1": $gray-1,
  "white": $white,
  "shadow": $shadow,
) !default;
