// * React-select - Structure
// <div class="react-select-container">
//   <div class="react-select__control">
//     <div class="react-select__value-container">...</div>
//     <div class="react-select__indicators">...</div>
//   </div>
//   <div class="react-select__menu">
//     <div class="react-select__menu-list">
//       <div class="react-select__option">...</div>
//     </div>
//   </div>
// </div>
.select-filter-container {
  display: block;
  width: 180px;
  // min-width: 180px;
  // max-width: 180px;
  height: calc(1.5em + 1.2rem);
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.3;
  background-clip: padding-box;
  color: $gray-4;
  // background-color: #ffffff;
  margin-bottom: 0.85rem;
  .select-filter__placeholder {
    font-size: 0.85rem;
  }
  .select-filter__indicator-separator {
    display: none;
  }
  .select-filter__control {
    border-radius: 8px;
    height: 100%;
  }
  .select-filter__control--is-focused {
    border: 2px solid $primary-100;
    box-shadow: none;
    box-sizing: border-box;
  }
}

// Select scrollbar customization
/* Scroll Bar */
.select-filter__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
.select-filter__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.select-filter__menu-list::-webkit-scrollbar-thumb {
  background: #888;
}
.select-filter__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

//date

.filter-input {
  display: block;
  padding: 0.375rem 1rem;
  height: calc(1.5em + 1.2rem);
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.3;
  background-clip: padding-box;
  border: 1px solid #d6d6d6;
  color: $gray-4;
  background-color: #ffffff;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 0.85rem;
  &:focus {
    border: 2.5px solid $primary-100;
    outline: 0;
  }

  &:read-only,
  &:read-only:focus {
    background-color: $gray-1;
    border: 1px solid #d6d6d6;
  }
}

.search-field {
  max-width: 220px !important;
}

.month-picker {
  width: 200px;
  margin: 0 10px;
  input {
    height: calc(1.5em + 1.2rem);
    padding: 0.375rem 1rem;
    cursor: pointer;
  }
  .icon {
    right: 5px;
    top: 20%;
  }
  .dropdown {
    width: 300px;
    top: 45px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px;
  }
  .month {
    padding: 0.8rem 0;
    width: 33.33%;
  }
  .month:hover {
    background-color: $primary-100;
    color: white;
  }
  .arrow-btn:hover {
    background-color: $primary-100;
    color: white;
    cursor: pointer;
  }
}
