.loan-history-table {
  table {
    border: 1px solid #d6d6d6;
    width: 100%;

    thead {
      background-color: #fff;
      border-bottom: 1px solid #d6d6d6;
      th {
        padding: 1rem;
        color: $black;
        font-weight: 500;
      }
    }
    tr {
      border-right: 1px solid #d6d6d6;
      border-bottom: 1px solid #d6d6d6;
      td {
        padding: 1rem;
      }
    }
  }
}
