.file-upload-container {
  position: relative;
  // margin: 25px 0 15px;
  border: 1px dashed #c8c8c8;
  padding: 12px 8px;
  border-radius: 6px;
  // margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  font-family: "Poppins", sans-serif;

  label {
    top: -46px;
    font-size: 16px;
    bottom: 16px;
    color: #3c4b64;
    left: 0;
    position: absolute;
  }
  p {
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    letter-spacing: 2.2px;
    margin-top: 15px;
    text-align: center;
  }
  // button {
  //   box-sizing: border-box;
  //   appearance: none;
  //   background-color: transparent;
  //   border: 2px solid #3498db;
  //   cursor: pointer;
  //   font-size: 1rem;
  //   line-height: 1;
  //   padding: 0.8em 2em;
  //   text-align: center;
  //   text-transform: uppercase;
  //   font-weight: 700;
  //   border-radius: 6px;
  //   color: #3498db;
  //   position: relative;
  //   overflow: hidden;
  //   z-index: 1;
  //   transition: color 250ms ease-in-out;
  //   width: 45%;
  //   display: flex;
  //   align-items: center;
  //   padding-right: 0;
  //   justify-content: center;
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     display: block;
  //     top: 0;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     width: 0;
  //     height: 100%;
  //     background: #3498db;
  //     z-index: -1;
  //     transition: width 250ms ease-in-out;
  //   }

  //   svg {
  //     font-size: 22px;
  //     margin-right: 5px;
  //     border-right: 2px solid;
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     width: 20%;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     height: 100%;
  //     padding: 8px;
  //   }
  //   @media only screen and (max-width: 500px) {
  //     width: 70%;
  //   }
  //   @media only screen and (max-width: 350px) {
  //     width: 100%;
  //   }
  //   &:hover {
  //     color: #fff;
  //     outline: 0;
  //     background: transparent;
  //     &:after {
  //       width: 110%;
  //     }
  //   }
  //   &:focus {
  //     outline: 0;
  //     background: transparent;
  //   }
  //   &:disabled {
  //     opacity: 0.4;
  //     filter: grayscale(100%);
  //     pointer-events: none;
  //   }
  // }
  input {
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    &:focus {
      outline: none;
    }
  }
}

.file-preview-container {
  // margin-bottom: 35px;
  span {
    font-size: 14px;
  }
  .preview-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    @media only screen and (max-width: 400px) {
      flex-direction: column;
    }
  }
  .preview-container {
    padding: 2px;
    width: 20%;
    height: 120px;
    margin: 0 5px;
    border-radius: 6px;
    box-sizing: border-box;
    &:hover {
      opacity: 0.95;
      .file-meta-data {
        display: flex;
      }
    }
    & > div:first-of-type {
      height: 100%;
      position: relative;
    }
    @media only screen and (max-width: 750px) {
      width: 25%;
    }
    @media only screen and (max-width: 500px) {
      width: 50%;
    }
    @media only screen and (max-width: 400px) {
      width: 100%;
      padding: 0 0 0.4em;
    }
  }
  img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }
  .file-meta-data {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    background-color: rgba(5, 5, 5, 0.5);
    aside {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
    }
  }
  .delete-icon {
    cursor: pointer;
    &:hover {
      transform: scale(1.3);
    }
  }
}
