.register {
  width: 85%;
  margin: 2rem auto;
}
/* .register img{
  max-width: 100%;
} */

.appear-animation {
  animation: appear 2s ease;
}

.register-btn {
  border-radius: 5px;
  width: 30%;
}

input:focus:invalid {
  border: 2px solid red;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .register {
    width: 95%;
  }
  .login-left {
    display: none !important;
  }
  .register-btn {
    width: 60%;
  }
}
