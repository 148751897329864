.employee-chart {
  height: 100%;
  @include flex(row, space-evenly, center);
  .employee-chart-container {
    width: 40%;
  }
  .employee-data-container {
    ul {
      list-style: none;
      padding: 0;
      margin-left: 15px;
    }
    li {
      @include flex();
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .employee-color {
      background-color: red;
      height: 24px;
      width: 24px;
      border-radius: 5px;
      flex-shrink: 0;
    }
    .employee-count {
      width: 100%;
      margin-left: 15px;
      @include flex(row, space-between, center);
      p {
        margin-bottom: 0;
        @include textStyle($gray-5, 16px, 400);
      }
      h6 {
        margin-bottom: 0;
        margin-left: 20px;
        flex-shrink: 0;
        @include textStyle($black, 16px, 600);
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .employee-chart {
    height: 100%;
    @include flex(column, space-evenly, center);
    .employee-chart-container {
      width: 60%;
      margin: 20px 0;
    }
    .employee-data-container {
      ul {
        margin-left: 0;
      }
    }
  }
}
