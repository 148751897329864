.hq-table-container {
  min-width: 100%;

  overflow-x: auto;
  .hq-table {
    width: 100%;

    tr {
      width: 100%;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
    }
    th,
    td {
      width: -moz-fit-content;
      min-width: fit-content;
      padding: 8px 5px;
      margin: 5px;
      white-space: nowrap;
      &:first-child {
        padding-left: 1rem;
      }
    }

    .action-container {
      margin-left: auto !important;
      @include flex(row, flex-end);
      width: -moz-fit-content;
      width: fit-content;
    }
    .action-button-container {
      @include flex();
      width: -moz-fit-content;
      width: fit-content;
      margin-left: auto !important;
    }
  }
}
.hq-table-container-no-scrollbar {
  min-width: 100%;

  overflow-x: hidden;
  .hq-table {
    width: 100%;

    tr {
      width: 100%;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
    }
    th,
    td {
      width: -moz-fit-content;
      min-width: fit-content;
      padding: 8px 5px;
      margin: 5px;
      white-space: nowrap;
      &:first-child {
        padding-left: 1rem;
      }
    }

    .action-container {
      margin-left: auto !important;
      @include flex(row, flex-end);
      width: -moz-fit-content;
      width: fit-content;
    }
    .action-button-container {
      @include flex();
      width: -moz-fit-content;
      width: fit-content;
      margin-left: auto !important;
    }
  }
}
.table-borderless td {
  padding: 8px 0px;
}

.fit-content {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
