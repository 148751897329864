.evaluation-form-container{
  overflow-x: scroll;
  .evaluation-form {
    min-width: fit-content;
    .form-header {
      border: 1px solid #D6D6D6;
    }
    .form-body{
      border-left: 1px solid #D6D6D6;
      border-right: 1px solid #D6D6D6;
      .factor, .sub-factor>div:first-child{
        border-right: 1px solid #D6D6D6;
      }
      .factor, .sub-factor{
        border-bottom: 1px solid #D6D6D6;
      }
      
    }
  }
}

.create-evaluation-form{
  .custom-mark-input input{
    padding-left: 40px;
  }
}
